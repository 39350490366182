/* @config '../tailwind.config.js' */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

@tailwind base;
@tailwind utilities;
@tailwind components;

@import "@webdatarocks/webdatarocks/webdatarocks.css";

@layer base {
	html {
		font-size: 16px;
	}
	h1 {
		@apply text-4xl;
	}
	h2 {
		@apply text-2xl;
	}
	h3 {
		@apply text-xl;
	}
	code {
		@apply font-mono;
		@apply bg-dark;
		@apply border-solid;
		@apply border-accent1;
		border-width: 1px;
		@apply rounded-lg;
		padding: 0px 2px;
		@apply text-white;
	}
	/* button {
		padding: 2px 6px;
		@apply rounded-xl;
		border-width: 1px;
		border-style: solid;
		@apply border-accent1;
	} */
}

@media only screen and (min-resolution: 192dpi) {
	html {
	  font-size: 12px; /* Adjust as needed */
	}
  }
  
  /* For Retina 3x displays (if applicable) */
  @media only screen and (min-resolution: 288dpi) {
	html {
	  font-size: 12px; /* Adjust as needed */
	}
  }

@layer utilities {
	.scrollbar::-webkit-scrollbar {
		width: 7px;
	}

	.scrollbar::-webkit-scrollbar-track {
		border-radius: 100vh;
		background: #0f0f0f;
		border: 1px solid #d9d9d9;
	}

	.scrollbar::-webkit-scrollbar-thumb {
		background: #d9d9d9;
		border-radius: 100vh;
		margin: 2px;
	}

	.scrollbar::-webkit-scrollbar-thumb:hover {
		background: #c0a0b9;
	}

	.special {
		@apply bg-shade;
		@apply text-sm;
		@apply text-accent1;
		border: 1px solid "#77D4E1";
		padding: 2px 2px;
		@apply rounded-lg;
	}
}
